import React from "react";
import { WeeklyIcon, MonthIcon, YearIcon } from "../../components/svg/index";

const PlanCard = ({ label, price, period, isCurrent, type, onPlanChange, planValue, updateModal }) => {
  const handleClick = (e) => {
    if (!updateModal) {
      console.log("clicked", e.target.value);
      onPlanChange(planValue);
    }
  };

  return (
    <div className={`card ${isCurrent ? "current" : ""} clickable-div`} onClick={handleClick}>
      <div>
        <p>{isCurrent ? "CURRENT PLAN" : ""}</p>
        <div className="icon">{type === "week" ? <WeeklyIcon /> : type === "month" ? <MonthIcon /> : <YearIcon />}</div>
        <h3 className="mt-2">{label}</h3>
        {planValue === "monthly-premium" && <p>$.64 / game</p>}
      </div>
      <div className="d-flex justify-content-center">
        <hr />
      </div>
      <div>
        <h2 className="price">${price}</h2>
        {planValue.includes("premium") && (
          <>
            {planValue === "weekly-premium" && <p className="p-0 m-0">$.71 / game</p>}
            {planValue === "monthly-premium" && <p className="p-0 m-0">$.64 / game</p>}
            {planValue === "yearly-premium" && <p className="p-0 m-0">$.43 / game</p>}
          </>
        )}
        <p>{period}</p>
      </div>
      <div>
        {planValue.includes("premium") && (
          <>
            <h5 className="text-left">✅ All Live Games</h5>
            <h5 className="text-left">✅ All predicted ShotQuality scores for NCAA</h5>
            <h5 className="text-left">✅ All historical ShotQuality scores for all leagues</h5>
          </>
        )}
      </div>
    </div>
  );
};

export default PlanCard;
