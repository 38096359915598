import React from "react";
import Image from "next/image";
import Link from "next/link";
import GreenCheckmark from "../GreenCheckmark/GreenCheckmark";
import DiscordLogo from "../../assets/images/discord_logo.png";
import { WeeklyIcon, MonthIcon } from "../svg";

function PriceBlock() {
  return (
    <div className="price-block p-2">
      <div className="container pt-5 pb-3 mt-3 mb-3">
        <div className="row">
          <div className="col-12 col-md-8">
            <h2 className="text-left">CHOOSE YOUR PLAN TO UNLOCK FULL ACCESS NOW!</h2>
          </div>
        </div>
        <div className="row mt-3 mb-5">
          <div className="col-12 col-md-6">
            <div className="d-flex gap-2 mt-4 align-items-center">
              <GreenCheckmark fill="#f26b0b" />{" "}
              <h4 className="mb-0 w-90">NBA, WNBA, and NCAA player, matchup, and team data</h4>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex gap-2 mt-4 align-items-center">
              <GreenCheckmark fill="#f26b0b" />{" "}
              <h4 className="mb-0 w-90">
                Access to{" "}
                <a href="https://discord.com/invite/shotquality" target="_blank" rel="noopener noreferrer">
                  Members Only Discord and premium analysis{" "}
                  <Image src={DiscordLogo.src} height={20} width={25} alt="green tick" />
                </a>
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex gap-2 mt-4 align-items-center">
              <GreenCheckmark fill="#f26b0b" />{" "}
              <h4 className="mb-0 w-90">
                Access to ScoreCenter, the only place for live regression data to power your live picks
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex gap-2 mt-4 align-items-center">
              <GreenCheckmark fill="#f26b0b" /> <h4 className="mb-0 w-90">Cancel Anytime</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <Link
              href="/register/day-pass"
              className="package-card d-flex justify-content-between align-items-center p-3 pt-4 pb-4 mt-4"
            >
              <div className="d-flex align-items-center flex-column mt-1 mb-1">
                <WeeklyIcon />
                <strong className="mt-3 d-block">Day Pass</strong>
              </div>
              <div className="text-right mt-1 mb-1">
                <h2 className="mb-0">$9.99</h2>
                <strong className="mt-3 d-block">1 Day NBA + WNBA ALL Access</strong>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6">
            <Link
              href="/register/wnba-season"
              className="package-card d-flex justify-content-between align-items-center p-3 pt-4 pb-4 mt-4"
            >
              <div className="d-flex align-items-center flex-column mt-1 mb-1">
                <MonthIcon />
                <strong className="mt-3 d-block text-center">WNBA Season Pass</strong>
              </div>
              <div className="text-right mt-1 mb-1">
                <h2 className="mb-0">
                  {" "}
                  <s className="text-gray" style={{ fontSize: "1rem" }}>
                    $299.99
                  </s>{" "}
                  $199.99
                </h2>
                <strong className="mt-3 d-block">ALL Access through WNBA Finals</strong>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center pt-5">
            <Link href="/welcome-content-monthly">
              <h3 className="is-link blink color-orange" style={{ fontWeight: "300" }}>
                Get EARLY access to the Pick of the Day and Premium Content for only $9.99 / mo
              </h3>{" "}
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="price-block-bg-image">
        <Image
          src="/static/media/price-block-bg.png"
          layout="fill"
          objectFit="cover"
          alt="price block background image"
        />
      </div> */}
    </div>
  );
}

export default PriceBlock;
